<template>
  <b-modal
    id="proceed-modal"
    title='Are you sure you want to send this processed application to billing?'
    header-bg-variant='success'
    header-text-variant='white'
    @show='is_drought ? modalTemplate = waterShortageApprovalTemplate : modalTemplate = approvalTemplate'
    @hide='notesAdded = false; noteTemplate = ""'
    hide-footer
    size="sm"
  >
    <div v-if='!notesAdded'>
      <i class=''>Please first add any notes to JDE.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Note Template</b-input-group-text>
        <b-form-select
          :disabled='notesAdded'
          v-model="noteTemplate"
          :options="noteTemplateOptions.slice(3,5)"
        ></b-form-select>
      </b-input-group>
      <b-form-textarea
        :disabled='notesAdded'
        id="approval-textarea-note"
        class='mt-1'
        placeholder="Select note template above..."
        v-model="noteTemplate"
        rows="9"
      ></b-form-textarea>
      <b-button :disabled='notesAdded' block class='mt-1' variant="secondary" @click='notesAdded = true'>
        Notes added to JDE
      </b-button>
    </div>

    <div v-if='notesAdded'>
      <i class=''>After adding notes, please email the customer.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Template</b-input-group-text>
        <b-form-select
          :disabled='!notesAdded'
          v-model="modalTemplate"
          :options="emailTemplateOptions.slice(7, 9)"
        ></b-form-select>
      </b-input-group>
      <b-form-textarea
        :disabled='!notesAdded'
        id="approval-textarea-email"
        class='mt-1'
        placeholder="Dear..."
        v-model="modalTemplate"
        rows="9"
      ></b-form-textarea>
      <b-button :disabled='!notesAdded' block class='my-1' variant="success" @click="onProceedAndEmail()">
        Yes, email and send to billing
      </b-button>
      <b-button :disabled='!notesAdded' block class='mb-1 mt-1' variant="success" @click="onProceed()">
        Send to billing without email
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import noteModalMixin from "@/mixins/noteModal";
import utils from "@/mixins/utils";

export default {
  name: "proceedModal",
  components: {},
  mixins: [noteModalMixin, utils],

  data() {
    return {
      modalId: "proceed-modal",
    }
  },
  methods: {
    onProceedAndEmail() {
      this.logEvent(
        this.adjustmentApplication.application_id,
        `${this.current_user} approved this application.`,
        this.modalTemplate,
      );

      this.updateState(this.adjustmentApplication.application_id, 'ready_for_billing', false);
      this.sendEmail(this.adjustmentApplication.application_id, this.modalTemplate);
      this.hide();
    },

    onProceed() {
      this.updateState(this.adjustmentApplication.application_id, 'ready_for_billing', true);
      this.hide();
    }
  }
}
</script>
