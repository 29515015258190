<template>
  <b-modal
    id="manual_event_log"
    title='Warning: Failed to log recent action!'
    header-bg-variant='warning'
    hide-footer
    size="sm"
  >
      <h6>Please manually provide a description of the action you just performed so we can try again to add this to the event log.</h6>
      <b-input-group
        prepend="Action Description"
        id="manual_event_log-description"
      >
        <b-form-input v-model="manual_event_desc"></b-form-input>
      </b-input-group>

      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Email Template (delete if none)</b-input-group-text>
        <b-form-select v-model="modalTemplate" :options="emailTemplateOptions"></b-form-select>
      </b-input-group>

      <b-form-textarea
        id="manual_event_log-email"
        class='mt-1'
        placeholder="Dear..."
        v-model="modalTemplate"
        rows="9"
      ></b-form-textarea>
      <b-button class='mt-1' block size="sm" variant="warning" @click="onLogEvent()">
        Log Event
      </b-button>
    </b-modal>
</template>

<script>
import noteModalMixin from "@/mixins/noteModal";
import utils from "@/mixins/utils";

export default {
  name: "manualEventLogModal",
  components: {},
  mixins: [noteModalMixin, utils],

  data() {
    return {
      modalId: "manual-event-log-modal",

      manual_event_desc: '',
    }
  },
  methods: {
    onLogEvent() {
      this.logEvent(
        this.adjustmentApplication.application_id,
        `${this.current_user}: ${this.manual_event_desc}`,
        this.modalTemplate
      );
    },

    reset() {
      this.manual_event_desc = '';
    },
  },
  created() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // Ensure we reset data when the modal is hidden so that we don't show the previous client's data when it's opened again
      if (modalId === this.modalId) {
        this.reset();
      }
    });
  },
}
</script>
