
/* eslint-disable no-undef */
export default {
  props: { },
  data() {
    return {
      noteTemplate: '',
    };
  },
  watch: {
    noteTemplate: function(val){
      if (val == this.noteTemplateOptions[4].value) {
        this.$store.commit("ui/setActionModalTemplate", this.approvalCV19Template);
      }
    },
  },
  computed: {
    adjustmentApplication() {
      return this.$store.state.ui.modalData;
    },
    isDrought() {
      return this.$store.state.ui.isDrought;
    },
    recentActivity() {
      return this.$store.state.ui.recentActivity;
    },
    current_user() {
      return this.$store.state.auth.user.username.toUpperCase();
    },
    selectedName() {
      return this.titleCase(this.adjustmentApplication.name);
    },

    next_week() {
      let today = new Date();
      let in_a_week = new Date().setDate(today.getDate()+7);
      let in_a_week_string = (new Date(in_a_week)).toISOString().split('T')[0];
      return in_a_week_string;
    },

    requestTemplate() {
      return dedent`
        Dear ${this.selectedName},

        We need more information!

        THIS IS A TEST.
      `;
    },
    conditionalApprovalTemplate() {
      /* eslint-disable no-irregular-whitespace */
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request has been reviewed and is conditionally approved. The approval is contingent upon your usage being back in budget (within Tiers 1 & 2) within the following two billing cycles of the high bill; otherwise, the submission will be denied.

        You will be contacted via e-mail with an update after your next billing cycle.

        Please visit our website at http://www.mnwd.com/watersavingtips for common causes of high water consumption and water saving tips to help you ensure your next bill is back within budget. You can also find videos about how to check your meter for leaks and how to troubleshoot your irrigation system on our website at https://www.mnwd.com/watersavingtools/.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
      /* eslint-enable no-irregular-whitespace */
    },
    approvalTemplate(){
      let credit = Number(this.adjustmentApplication.bill_credit)
      return dedent`
        Dear ${this.selectedName},

        Your Bill Adjustment Request has been approved. You will receive a credit of $${credit.toFixed(2)} back to your account. Please allow up to two (2) billing cycles for this credit to be reflected on your account statement.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    approvalSOETemplate() {
      let credit = Number(this.adjustmentApplication.bill_credit);
      let highBillDate = this.adjustmentApplication.bill_to_adjust_date;
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request for your bill on ${highBillDate} has been reviewed and approved. You will receive a credit of $${credit.toFixed(2)} back to your account. Please allow up to 2 billing cycles for this credit to be reflected on your account statement.

        We recognize that this is a difficult time for everyone. Adjustments are made to bills that reach Tiers 4 and 5 of our Water Rates, and customarily, those units are adjusted down to the Tier 3 rate once the account comes back within the personalized water budget. While we are under the State of Emergency, Moulton Niguel Water District’s Board of Directors is temporarily granting credits for the additional charges applied in Tiers 4 and 5 without having to wait for the bill to come back in budget. However, we would still like the opportunity to help you reduce your overall bill and ensure that you don’t have any continuing issues at your property that could be causing further high bills or property damage from secondary issues like broken toilets or irrigation valves. Please review our Troubleshooting Guide that we’ve prepared to help customers identify why they may be in Tiers 4 or 5 of their personalized water budgets. Please know we are here to support you and would be happy to talk through any of the steps on the Troubleshooting Guide over the phone or via video chat. Please call us at (949) 448-4025 or email customerservice@mnwd.com if you have any questions or would like to set up a video chat appointment.

        Our Troubleshooting Guide can be found here: https://bit.ly/HSS-Guidelines-4-29
      `;
    },
    waterShortageApprovalTemplate(){
      let credit = Number(this.adjustmentApplication.bill_credit);
      return dedent`
        Dear ${this.selectedName},

        Our current MNWD Water Shortage Contingency Plan stage does not allow for bill adjustments. However, your Bill Adjustment Request has been approved upon further review. You will receive a credit of $${credit.toFixed(2)} to your account. Please allow up to two (2) billing cycles for this credit to be reflected on your account statement.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    hssTemplate() {
      let highBillDate = this.adjustmentApplication.bill_to_adjust_date;
      let dateifed_highBillDate = new Date(highBillDate);
      let plus_one_month = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
      );
      let plus_two_months = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
      );
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request was approved by management. However, as stated on the webform and in our Adjustment Policy, adjustment credits are contingent on the customer's usage coming back within their water budget (Tiers 1 and 2) within 2 billing cycles of the repair to demonstrate that all issues have been resolved.

        Your ${plus_one_month} and ${plus_two_months} bills following your repair were over budget, therefore, we would presently have to deny the credit adjustment due to MNWD’s Board Policy. However, your bills may be indicative of an ongoing issue and we would still like to help you with your water consumption and bills. If you would like to set up a free Home Savings Survey, it would extend your bill adjustment request deadline by one month and would give you great water savings tips and troubleshooting help. We would come to your home to check your meter, look at your irrigation, and check over your irrigation timer. The offer will expire by ${this.next_week} and if we don't hear back from you , we would have to deny the credit adjustment.

        If you would like to sign up for a free, Home Savings Survey, please fill out the request on our website at https://www.mnwd.com/homesavingssurvey/.

        If you would like help troubleshooting any remaining issue, or if you have any further questions, please give us a call at 949-448-4025, or email us at customerservice@mnwd.com.
      `;
    },
    denyTemplate_General() {
      return dedent`
        Dear ${this.selectedName},

        We would like to inform you that your Bill Adjustment Request has been denied. The District’s Adjustment Policy states that each account qualifies for three courtesy bill adjustments. This Bill Adjustment Request exceeds that limit, and  we are unable to grant your adjustment credit.

        Recurring high bills may be indicative of an ongoing issue. Please visit our <a href="https://www.mnwd.com/fix-a-leak/">Fix-a-Leak</a> webpage for helpful tips and common solutions to continuous water movement.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    denyDueTobudgetTemplate() {
    },
    denyTemplate_T3() {
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request has been reviewed, however the request is Denied. We appreciate that your usage is back within budget, however, your past several months' usage only reached into Tier 3 of our Water rates which is our cost of water. Adjustments are made to bills that reach Tiers 4 and 5 of our Water Rates, and in that case, those units are adjusted down to the Tier 3 rate, so there is no possible adjustment to be made in this case.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    denyTemplate_T2_RECYCLED() {
      return dedent`
        Dear ${this.selectedName},

        We would like to inform you that your Bill Adjustment Request has been denied. Bill adjustments recalculate units of water charged in Tiers 3 and 4 to the Tier 2 rate. However, your water usage for the requested bill did not exceed Tier 2, so there is no possible adjustment to be made.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    denyTemplate_NO_ADJUSTABLE_BILL() {
      return dedent`
        Dear ${this.selectedName},

        We would like to inform you that your Bill Adjustment Request has been denied. Credits are applied to bills that reach Tiers 4 and 5 of our water rates to adjust those units to Tier 3 rates. However, your water usage for the requested bill did not exceed  Tier 3 of our water rates, so there is no possible adjustment to be made.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    denyTemplate_NO_ADJUSTABLE_BILL_WITHIN_90_DAYS() {
      return dedent`
        Dear ${this.selectedName},

        We would like to inform you that your Bill Adjustment Request has been denied. The District’s Adjustment Policy states that adjustments can only be made to bills within ninety days following the affected bill. The requested bill date is not within that timeframe.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    denyTemplate_2_BILLS_OVER() {
      let type = '';
      if (this.adjustmentApplication.pool_fill_reason) {
        type  = 'pool fill';
      } else if (this.adjustmentApplication.timer_description) {
        type  = 'timer malfunction';
      } else if (this.adjustmentApplication.repair_description) {
        type  = 'repair';
      } else if (this.adjustmentApplication.covid_reason) {
        type  = 'COVID-related incident';
      } else {
        type = 'qualifying event resolution';
      }

      let highBillDate = this.adjustmentApplication.bill_to_adjust_date
      let dateifed_highBillDate = new Date(highBillDate)

      let plus_one_month = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
      );
      let plus_two_months = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
      );
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request was approved by management. However, as stated on the webform and in our Adjustment Policy, adjustment credits are contingent on the customer's usage coming back within their water budget (Tiers 1 and 2) within 2 billing cycles of the repair. Your ${plus_one_month} and ${plus_two_months} bills following your ${type} were over budget, therefore, we have to deny the credit adjustment.

        Your bills may be indicative of an ongoing issue. If you would like help troubleshooting any remaining issue, or if you have any further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
    },
    reminderTemplate() {
      /* eslint-disable no-irregular-whitespace */
      return dedent`
        Dear ${this.selectedName},

        Your bill adjustment request has been reviewed and conditionally approved. The approval is contingent upon your water usage being back within budget (usage in Tiers 1 & 2 only) within two billing cycles of your high bill.

        You exceeded your water budget on your first bill following your high bill. Therefore, your usage will need to be back within budget on your NEXT billing cycle; otherwise, your request will be DENIED.

        At this time of year, the most common reason for customers being over their allocation is overwatering their landscape. To learn how to review your irrigation timer settings and how to check your meter for leaks, please visit our website at http://www.mnwd.com/watersavingtools . It also covers other common causes of high water consumption and water saving tips to help you ensure your next bill is back within budget.

        For further questions, please email our Customer Service Team at customerservice@mnwd.com or call us at (949) 831-2500. Our team is available to take your call Monday through Friday, from 8 am to 5 pm. Anyone on our team can help you.
      `;
      /* eslint-enable no-irregular-whitespace */
    },

    noteTemplateOptions() {
      let type = '';
      if (this.adjustmentApplication.pool_fill_reason) {
        type  = 'POOL FILL';
      } else if (this.adjustmentApplication.timer_description) {
        type  = 'TIMER MALFUNCTION';
      } else if (this.adjustmentApplication.repair_description) {
        type  = 'LEAK REPAIR';
      } else if (this.adjustmentApplication.covid_reason) {
        type  = 'COVID-RELATED INCIDENT';
      }

      let highBillDate = this.adjustmentApplication.bill_to_adjust_date;
      let potential_credit = Number(this.adjustmentApplication.bill_credit).toFixed(2);
      let original_total_bill = this.adjustmentApplication.bill_to_adjust_amount;
      let adjusted_total_bill = this.adjustmentApplication.bill_amount_after_adjustment;
      let today = (new Date()).toISOString().split('T')[0];
      let dateifed_highBillDate = new Date(highBillDate);
      let plus_one_month = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
        .toUpperCase()
      );
      let plus_two_months = (
        new Date(dateifed_highBillDate
        .setMonth(dateifed_highBillDate.getMonth()+1))
        .toLocaleString('en-us', { month: 'long' })
        .toUpperCase()
      );

      // Routine to update JDE note templates with director decision information during drought
      let director_decision = '';
      try {
        if (this.adjustmentApplication.is_director_approved !== '' && this.isDrought && this.recentActivity.length > 0) {
          // iterate through recentActivity logs to find name of director who approved or denied application
          for (let i = 0; i < this.recentActivity.length; i++) {
            let decision_from_recent_activity = ` ${this.recentActivity[i]['description'].toUpperCase()};`
            // This conditional finds the logged event with the director name by using its description string
            if (decision_from_recent_activity.slice(0, 26) == ' THIS APPLICATION RECEIVED') {
              director_decision = decision_from_recent_activity;
              break
            }
          }
        }
      } catch(err) {
        console.log(err);
      }


      let noteTemplateOptions = [
        { text: 'Conditionally Approved, Waiting on first bill post high bill',
          value: `CONDITIONALLY APPROVED FOR ${type}: ${this.current_user} APPROVED ADJ FOR ${highBillDate} BILL, PENDING USAGE W/IN BUDGET, WAITING ON 1ST BILL. POT CREDIT OF $${potential_credit}. EMAILED CUST VIA BILL ADJUSTMENT APP TO INFORM OF APPROVAL PENDING BUDGET.` },
        { text: 'Conditionally Approved, First bill over, waiting on 2nd bill',
          value: `CONDITIONALLY APPROVED FOR ${type}: ${this.current_user} APPROVED ADJ FOR ${highBillDate} BILL, PENDING USAGE W/IN BUDGET, ${plus_one_month} BILL OVER BUDGET, WAITING ON 2ND ${plus_two_months} BILL. POT CREDIT OF $${potential_credit}. EMAILED CUST VIA BILL ADJUSTMENT APP TO INFORM OF APPROVAL PENDING BUDGET.` },
        { text: 'HSS Survey Offer',
          value: `${this.current_user} EMAILED AND CALLED TO XXX-XXX-XXXX L/M ON V/M ${today} FOR CUST’S ${highBillDate} BILL ADJ REQ BEING 2 BILL CYCLES OVER ALLOCATION AND HAVING TO DENY REQUEST PER MNWD BOARD POLICY. HOWEVER, CUST IS BEING OFFERED A ONE MONTH EXTENS IF REQUEST HSS, OFFER EXPIRATION OF ${this.next_week}, OTHERWISE ADJ REQ WILL BE DENIED.` },

        { text: 'Approved',
          value: `CREDIT FOR ${type}: ${this.current_user} APPROVED ADJ APP FOR ${highBillDate} BILL;${director_decision} ORIG BILL AMT OF $${original_total_bill}, ADJ BILL AMT OF $${adjusted_total_bill}, CREDIT $${potential_credit} CONS W/IN BUDGET; EMAILED VIA BILL ADJUSTMENT APP`},
        // { text: 'Approved SOE',
          // value: `CREDIT FOR ${type}: ${this.current_user} APPROVED ADJ APP FOR ${highBillDate} BILL;${director_decision} ORIG BILL AMT OF $${original_total_bill}, ADJ BILL AMT OF $${adjusted_total_bill}, CREDIT $${potential_credit}; EMAILED VIA BILL ADJUSTMENT APP`},

        { text: 'Denied - No adjustable bill (NO ADJUSTABLE BILL W/IN 60 DAYS)',
          value: `DENIED: ONLINE ADJ ${highBillDate} FOR ${type}; REASON: NO ADJUSTABLE BILL W/IN 60 DAYS; CALLED AND SPOKE TO CUST; EMAILED VIA BILL ADJUSTMENT APP.`},
        { text: 'Denied - No adjustable bill (T3 ONLY)',
          value: `DENIED: ONLINE ADJ ${highBillDate} FOR ${type}; REASON: T3 ONLY; CALLED AND SPOKE TO CUST; EMAILED VIA BILL ADJUSTMENT APP.`},
        { text: 'Denied - 2 Bills over budget',
          value: `DENIED: ${this.current_user} APPROVED ADJ FOR ${highBillDate} BILL (${type}), PENDING USAGE W/IN BUDGET, HOWEVER CONS STILL OVER BUDGET AFTER 2 BILLS. ORIG AMT $${original_total_bill}, ADJ AMT $${adjusted_total_bill}, POT CRDT $${potential_credit}. [[L/M ON ACCT PH W/ ID [NUMBER]] EXPLAINING DENIAL D/T CONS OVER BUDGET AND INFORMING CUST TO CALL US BACK IF WOULD LIKE HELP TROUBLESHOOTING THE REMAINING PROBLEM.]`},
        { text: 'Denied - Duplicate application.',
          value: `DENIED: ${this.current_user} DETERMINED AS DUPLICATE APPLICATION FOR ${type}.`},
      ];

      // Add in water shortage denial template if we're in a water shortage phase and a director denied
      if (this.adjustmentApplication.is_director_approved === false && this.isDrought) {
        let water_shortage_denial_template = {
          text: 'Denied - Water Shortage.',
          value: `DENIED: WATER SHORTAGE; ${director_decision}`
        };
        noteTemplateOptions.push(water_shortage_denial_template);
      }

      return noteTemplateOptions;
    },
    emailTemplateOptions() {
      let emailTemplateOptions = [
        // 0
        { text: 'Conditionally Approved, Waiting on first bill post high bill',
          value: this.conditionalApprovalTemplate },
        // 1
        { text: 'Conditionally Approved, First bill over, waiting on 2nd bill',
          value: this.reminderTemplate },
        // 2
        { text: 'HSS Offer',
          value: this.hssTemplate },

        // 3
        { text: 'Denied - General',
        value: this.denyTemplate_General },
        // 4
        { text: 'Denied - No Adjustable Bill (Residential)',
          value: this.denyTemplate_NO_ADJUSTABLE_BILL },
        // 5
        { text: 'Denied - No Adjustable Bill (Irrigation & Recycled)',
        value: this.denyTemplate_T2_RECYCLED },
        // 6
        { text: 'Denied - No Adjustable Bill Within 90 Days',
        value: this.denyTemplate_NO_ADJUSTABLE_BILL_WITHIN_90_DAYS },

        // 7
        { text: 'Approval - General',
          value: this.approvalTemplate },

        // 8
        { text: 'Approval - Drought  ',
          value: this.waterShortageApprovalTemplate },

        // 9
        { text: 'Request',
          value: this.requestTemplate }
      ]

      // add signature to all email templates
      let signature = dedent`


        Sincerely,

        Customer Service
        Moulton Niguel Water District
      `;
      for (let i = 0; i < emailTemplateOptions.length; i++) {
         emailTemplateOptions[i].value = emailTemplateOptions[i].value + signature;
       }

      return emailTemplateOptions;
    },

    modalTemplate: {
      get() {
        return this.$store.state.ui.actionModalTemplate;
      },
      set(newVal) {
        this.$store.commit("ui/setActionModalTemplate", newVal);
      }
    },
    notesAdded: {
      get() {
        return this.$store.state.ui.notesAdded;
      },
      set(newVal) {
        this.$store.commit("ui/setNotesAdded", newVal);
      }
    },
  },
  methods: {
    titleCase(str) {
      if (str) {
        str = str.replace(/ */,' ').toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ').trim();
      } else {
        return str;
      }
    },
    reset() {
      this.noteTemplate = '';
    },

    hide() {
      this.$bvModal.hide(this.modalId);
    },
  },
  created() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // Ensure we reset data when the modal is hidden so that we don't show the previous client's data when it's opened again
      if (modalId === this.modalId) {
        this.reset();
      }
    });
  },
};
