<!--
  Component template that renders and implements all common things to all details modals,
  such as the modal header, buttons for expanding, closing, and saving.
-->

<template>
  <b-modal
    :id="modalId"
    v-model="visible"
    hide-footer
    hide-header-close
    header-class="detailsModalTitle pb-2"
    body-class="pt-0 pb-0"
    hide-backdrop
    content-class="detailsModalContent"
    v-bind:modal-class="{ expanded: expanded }"
    scrollable
  >
    <!-- Header -->
    <template #modal-title>
      <slot name="modal-header">
        {{ modalTitle }}

        <slot name="action-buttons">
        </slot>
        <!-- <b-button
          variant="light"
          class="primary ml-3 float-right"
          @click="!noActions && $emit('preSave')"
          :disabled="!saveEnabled"
          >SAVE & CLOSE</b-button
        > -->
        <b-button
          variant="outline"
          class="white ml-3 float-right"
          @click="(bvEvent) => $emit('cancel', { bvEvent, modalId })"
          >CANCEL</b-button
        >
        <b-button
          variant="outline"
          class="text-white ml-3 float-right"
          @click="expanded = !expanded"
          >{{ expanded ? "MINIMIZE" : "EXPAND" }}</b-button
        >
      </slot>
    </template>

    <!-- Loading indicator -->
    <b-row
      v-if="loading"
      style="height: 300px; margin-top: calc(45%)"
      class="justify-content-center text-center"
    >
      <div
        class="spinner-border text-dark justify-content-center"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </b-row>
    <!-- Actual Data (not loading) -->
    <b-row v-else>
      <!-- Info Panel (panel) -->
      <b-col class="pt-4 pb-3 dataColumn">
        <div
          v-for="key in displayedKeys"
          :key="key"
          class="mb-4 dataColumnItem"
        >
          <div class="text-uppercase" style="font-weight: 500">
            {{ key.replaceAll("_", " ") }}
          </div>
          <div v-if="key === 'created_ts'">
            {{ modalData.created_ts ? modalData.created_ts.replace('T', ' ') : '' }}
          </div>
          <div v-else-if="key === 'name'">
            {{ capitalize(modalData.name) }}
          </div>
          <div v-else-if="key === 'class'">
            {{ modalData.business_name === null ? 'Residential' : 'Commercial' }}
          </div>

          <div v-else-if="key === 'account_number'">
            {{ accountNumber }}
          </div>
          <div v-else-if="key === 'email'" class="text-lowercase">
            {{ modalData.email || "-" }}
          </div>
          <div v-else-if="key === 'service_address_full'">
            {{ capitalize(modalData.service_address_full) }}
          </div>

          <div v-else-if="key === 'bill_credit'">
            {{ dollarUS.format(modalData.bill_credit) || "-" }}
          </div>
          <div v-else>
            {{
              modalData[key]
                ? capitalize(modalData[key]).replaceAll("_", " ")
                : "-"
            }}
          </div>
        </div>
      </b-col>
      <!-- Main Panel (right) -->
      <b-col class="pt-4 pb-3 modalColumn" style="max-width: 100%">
        <slot name="mainBody"></slot>
      </b-col>
    </b-row>

    <slot name="footer"></slot>
  </b-modal>
</template>

<script>

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    undo: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    saveEnabled: {
      type: Boolean,
      default: false,
    },
    canEditAccountNumber: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      expanded: false,
      disableActions: false,
      visible: false,
      newAccountNumber: "",
      oldAccountNumber: null,
      displayedKeys: [
        "state",
        "created_ts",
        "class",
        "account_number",
        "service_address_full",
        "name",
        "email",
        "phone",
        "bill_cycle",
        "bill_credit",
        "is_paper",
        "is_covid_related",
      ],
      dollarUS: Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }),
    };
  },
  methods: {
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    getTimezoneOffset(d, tz) {
      // Get's an ISO8601 timezone offset string. i.e.: -07:00
      const a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
      a[1]--;
      const t1 = Date.UTC.apply(null, a);
      const t2 = new Date(d).setMilliseconds(0);
      const minutes = (t2 - t1) / 60 / 1000;

      return `${minutes > 0 ? '-' : '+'}${this.pad(Math.abs(minutes / 60), 2)}:${this.pad(minutes % 60, 2)}`;
    },
    dateToLocale(dateString, tz='UTC') {
      const dateObject = new Date(dateString + this.getTimezoneOffset(new Date(dateString), tz));
      const day = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][dateObject.getDay()];
      const datetime = dateObject.toLocaleString(
        "en-US",
        {
          timeZoneName:'short',
        }
      );
      return `${day}, ${datetime}`;
    },
    prettyPrint(something = "") {
      return something.replaceAll("_", " ").toUpperCase();
    },
  },
  computed: {
    modalData() {
      return this.$store.state.ui.modalData;
    },
    accountNumber() {
      if (this.modalData.account_number) return this.modalData.account_number;
      return "-";
    },
    users() {
      const allUsers = this.$store.state.ui.users.map((user) => ({
        text: user.username,
        value: user.id,
      }));
      return [{ text: "UNASSIGNED", value: null }].concat(allUsers);
    },
  },
  created() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.$emit("modalHide", { bvEvent, modalId });
    });

    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.$emit("modalShow", { bvEvent, modalId });
    });
  },
  watch: { },
};
</script>

<style>
.preferredBadge {
  border-radius: 10px !important;
  font-weight: 400 !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.expanded {
  padding: 0 !important;
  width: 100%;
  min-height: 100vh !important;
}

.expanded > .modal-dialog {
  float: unset !important;
  margin: 0;
  padding: 0;
}

.expanded > .modal-dialog > div {
  width: 100%;
  min-height: 100vh !important;
}

.modal {
  padding-right: 0px !important;
}

.modal-dialog {
  height: calc(100vh - 160px);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.modal-open {
  overflow: initial !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 79px;
  height: 35px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(246, 246, 246, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid rgba(169, 179, 182, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 4px;
  bottom: 2px;
  background-color: rgba(169, 179, 182, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider:before {
  background-color: #28b62c;
}

input:checked + .slider {
  background-color: rgba(40, 182, 44, 0.15);
  border: 2px solid rgba(40, 182, 44, 0.15);
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

input + .slider > span {
  margin-top: 8px;
}

input:not(:checked) + .slider.round > span[id="off"] {
  margin-left: 37px;
  position: absolute;
  color: rgba(169, 179, 182, 1);
}

input:checked + .slider.round > span[id="on"] {
  margin-left: 8px;
  position: absolute;
  color: rgba(40, 182, 44, 1);
}

input:not(:checked) + .slider.round > span[id="on"] {
  display: none;
}

input:checked + .slider.round > span[id="off"] {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dataColumn {
  max-width: 250px !important;
  margin: 0 30px;
  padding-left: 0px;
  padding-right: 0px;
}

.dataColumnItem {
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
