<template>
  <form>
    <div class="row">
      <div class="col">
        <div class="input-group mb-3">
          <input ref="input" class="form-control" v-model="localValue" placeholder="???" disabled />
          <div class="input-group-append">
            <button ref="editBtn" class="btn btn-outline-secondary" type="button"
              @click="onEditClick"
            ><b-icon icon="pencil"></b-icon></button>
          </div>
          <div class="input-group-append">
            <button disabled ref="submitBtn" class="btn btn-outline-secondary" type="button"
              @click="onSubmitClick"
            >Submit</button>
          </div>
        </div>
      </div>
    </div>
    </form>
</template>

<script>
export default {
  name: "editFieldInput",
  components: { },
  mixins: [],
  props: {
    value: String,
  },
  emits: [
    'onSubmit',
  ],
  data() {
    return {
      newValue: null,
    };
  },
  computed: {
    localValue: {
      get() {return this.newValue ? this.newValue : this.value},
      set(newVal) { this.newValue = newVal }
    },
  },
  methods: {
    onEditClick() {
      this.$refs.input.disabled = false;
      this.$refs.submitBtn.disabled = false;
      this.$refs.editBtn.disabled = true;
    },
    onSubmitClick() {
      this.$refs.input.disabled = true;
      this.$refs.submitBtn.disabled = true;
      this.$refs.editBtn.disabled = false;

      this.$emit('onSubmit', this.newValue);
    }
  },
  watch: {
  },
}
</script>
