<template>
  <b-modal
    id="pdf-modal"
    v-model="pdfModal"
    hide-footer
    modal-class="full-screen pa-0 ma-0"
    size="xl"
  >
    <template v-slot:modal-header>
      <b-button variant="light" class="float-right" @click="close"
        >CLOSE</b-button
      >
    </template>

    <object :data="pdfUrl" type="application/pdf" width="100%" height="100%">
      alt: <a :href="pdfUrl">{{ pdfUrl }}</a>
    </object>
  </b-modal>
</template>

<script>
export default {
  methods: {
    close() {
      this.$store.commit("ui/hidePDFModal");
    },
  },
  computed: {
    pdfModal: {
      get() {
        return this.$store.state.ui.pdfModal;
      },
      set(val) {
        this.$store.state.ui.pdfModal = val;
      },
    },
    pdfUrl() {
      return this.$store.state.ui.pdfUrl + "#toolbar=0&navpanes=0&scrollbar=0";
    },
  },
};
</script>

<style>
.full-screen > div {
  width: 878px;
}

.full-screen > div > div {
  height: 100%;
  background-color: rgba(5, 5, 5, 0);
  border: 0;
}

.full-screen > div > div > header {
  display: inline-block !important;
  border: 0;
}
</style>
