<template>
  <b-modal
    id="request-modal"
    title="Are you sure you want to email this customer?"
    header-bg-variant='warning'
    @show='modalTemplate = ""'
    @hide='notesAdded = false; noteTemplate = "";'
    hide-footer
    size="sm"
  >
    <div v-if='!notesAdded'>
      <i class=''>Please first add any notes to JDE.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Note Template</b-input-group-text>
        <b-form-select :disabled='notesAdded' v-model="noteTemplate" :options="noteTemplateOptions.slice(0, 3)"></b-form-select>
      </b-input-group>
      <b-form-textarea
      :disabled='notesAdded'
      id="request-textarea-note"
      class='mt-1'
      placeholder="Select note template above..."
      v-model="noteTemplate"
      rows="9"
      ></b-form-textarea>
      <b-button :disabled='notesAdded' block class='mt-1' variant="secondary" @click='notesAdded = true'>Notes added to JDE</b-button>
    </div>
    <div v-if='notesAdded'>
      <i class=''>After adding notes, please email the customer.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Email Template</b-input-group-text>
        <b-form-select :disabled='!notesAdded' v-model="modalTemplate" :options="emailTemplateOptions.slice(0, 3)"></b-form-select>
      </b-input-group>
      <b-form-textarea
      :disabled='!notesAdded'
      id="request-textarea-email"
      class='mt-1'
      placeholder="Dear..."
      v-model="modalTemplate"
      rows="9"
      ></b-form-textarea >
      <b-button :disabled='!notesAdded' block class='mb-1 mt-1' variant="warning" @click="onSnooze()">
        Yes, email and snooze until <b>next expected bill</b> <i>(<span v-html='adjustmentApplication.wake_up_date'></span>)</i>
      </b-button>
      <b-button :disabled='!notesAdded' block class='mb-1 mt-1' variant="warning" @click="onSnoozeWeek()">
        Yes, email and snooze until <b>next week</b> <i>(<span v-html='next_week'></span>)</i>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import noteModalMixin from "@/mixins/noteModal";
import utils from "@/mixins/utils";

export default {
  name: "requestModal",
  components: {},
  mixins: [noteModalMixin, utils],

  data() {
    return {
      modalId: "request-modal",
    }
  },
  methods: {
    snoozeUntil(wakeUpDate) {
      this.logEvent(
        this.adjustmentApplication.application_id,
        `${this.current_user} emailed this customer (${this.noteTemplate}) and snoozed until ${wakeUpDate}.`,
        this.modalTemplate,
      );

      this.updateState(
        this.adjustmentApplication.application_id,
        'waiting',
        false,
        this.noteTemplate,
        wakeUpDate,
      );
      this.sendEmail(this.adjustmentApplication.application_id, this.modalTemplate);
      this.hide();
    },

    onSnooze() {
      // The wake_up_date property is updated as part of the genericAdjustmentApplicationModal data fetching.
      // TODO: should be done server side
      this.snoozeUntil(this.adjustmentApplication.wake_up_date);
    },

    onSnoozeWeek() {
      this.snoozeUntil(this.next_week);
    }
  }
}
</script>
