
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 v-if="headerText">{{ headerText }}</h5>
        <b-form-group class="pt-4 pb-5">
          <b-form-radio-group
            v-model="localValue"
            :options="radioOptions"
            :state="localValue"
          >
            <span :id="'pop-' + id">&nbsp;</span>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div :id="'zachor-' + id" style='z-index: 10 !important;'></div>
      <b-popover :container="'zachor-' + id" v-if="showPopover" show :target="'pop-' + id">
        <slot name="popoverBody">
        </slot>
      </b-popover>
    </div>

    <div class="row">
      <div class="col-12">
        <slot name="details">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

let count = 0;
export default {
  name: "radioWithContext",
  components: { },
  mixins: [],
  props: {
    id: {
      type: String,
      default: function() {
        return this.$options.name + '-' + count++;
      }
    },
    value: Boolean,
    headerText: String,
    popoverCondition: { type: Function },
  },
  emits: [
    'input',
  ],
  data() {
    return {
      radioOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    },
    showPopover() {
      if (this.popoverCondition !== undefined) {
        return this.popoverCondition();
      } else {
        return this.localValue === false;
      }
    }
  },
  methods: {
  },
  watch: {
  },
}
</script>
