<template>
  <b-modal
    id="snooze-modal"
    title='Are you sure you want to snooze without emailing this customer?'
    header-bg-variant='warning'
    @hide='noteTemplate = ""'
    hide-footer
    size="sm"
  >
    <i class=''>Add any custom notes:</i>
    <b-form-textarea
      id="snooze-textarea"
      class='mt-1'
      placeholder="Notes, notes, notes..."
      v-model="noteTemplate"
      rows="9"
    ></b-form-textarea>
    <b-button class='mb-1 mt-1' block variant="warning" @click="onSnooze()">
      Yes, snooze until <b>next expected bill</b> <i>(<span v-html='adjustmentApplication.wake_up_date'></span>)</i>
    </b-button>
    <b-button class='mb-1 mt-1' block variant="warning" @click="onSnoozeWeek()">
      Yes, snooze until <b>next week</b> <i>(<span v-html='next_week'></span>)</i>
    </b-button>
  </b-modal>
</template>

<script>
import noteModalMixin from "@/mixins/noteModal";
import utils from "@/mixins/utils";

export default {
  name: "snoozeModal",
  components: {},
  mixins: [noteModalMixin, utils],

  data() {
    return {
      modalId: "snooze-modal",
    }
  },
  methods: {
    snoozeUntil(wakeUpDate) {
      this.logEvent(
        this.adjustmentApplication.application_id,
        `${this.current_user} snoozed this application until ${wakeUpDate} (no email)`,
        this.modalTemplate,
      );

      this.updateState(
        this.adjustmentApplication.application_id,
        'waiting',
        false,
        this.noteTemplate,
        wakeUpDate,
      );
      this.hide();
    },

    onSnooze() {
      // The wake_up_date property is updated as part of the genericAdjustmentApplicationModal data fetching.
      // TODO: should be done server side
      this.snoozeUntil(this.adjustmentApplication.wake_up_date);
    },

    onSnoozeWeek() {
      this.snoozeUntil(this.next_week);
    }
  }
}
</script>
