<template>
  <b-modal
    :id="modalId"
    title="Are you sure you want to deny this customer?"
    header-bg-variant='danger'
    header-text-variant='white'
    @show='modalTemplate = ""'
    @hide='notesAdded = false; noteTemplate = "";'
    hide-footer
    size="sm"
  >
    <div v-if='!notesAdded'>
      <i class=''>Please first add any notes to JDE.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Note Template</b-input-group-text>
        <b-form-select
          :disabled='notesAdded'
          v-model="noteTemplate"
          :options="noteTemplateOptions.slice(5, 10)"
        ></b-form-select>
      </b-input-group>
      <b-form-textarea
        :disabled='notesAdded'
        id="deny-textarea-note"
        class='mt-1'
        placeholder="Select note template above..."
        v-model="noteTemplate"
        rows="6"
      ></b-form-textarea>
      <b-button :disabled='notesAdded' block class='mt-1' variant="secondary" @click='notesAdded = true'>
        Notes added to JDE
      </b-button>
    </div>

    <div v-if='notesAdded'>
      <i class=''>After adding notes, please email the customer.</i>
      <b-input-group class='mt-2'>
        <b-input-group-text slot="prepend">Selected Template</b-input-group-text>
        <b-form-select
          :disabled='!notesAdded'
          v-model="modalTemplate"
          :options="emailTemplateOptions.slice(3, 6)"
        ></b-form-select>
      </b-input-group>
      <b-form-textarea
        :disabled='!notesAdded'
        id="deny-textarea-email"
        class='mt-1'
        placeholder="Dear..."
        v-model="modalTemplate"
        rows="9"
      ></b-form-textarea>
      <b-button :disabled='!notesAdded' block class='mb-1 mt-1' variant="danger" @click="onDenyAndEmail()">
        Email and deny
      </b-button>
      <b-button :disabled='!notesAdded' block class='mb-1 mt-1' variant="danger" @click="onDeny()">
        Deny without email
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import noteModalMixin from "@/mixins/noteModal";
import utils from "@/mixins/utils";

export default {
  name: "denyModal",
  components: {},
  mixins: [noteModalMixin, utils],

  data() {
    return {
      modalId: "deny-modal",
    }
  },
  methods: {
    onDenyAndEmail() {
      this.logEvent(
        this.adjustmentApplication.application_id,
        `${this.current_user} denied this application.`,
        this.modalTemplate,
      );

      this.updateState(this.adjustmentApplication.application_id, 'denied', false);
      this.sendEmail(this.adjustmentApplication.application_id, this.modalTemplate);
      this.hide();
    },

    onDeny() {
      this.updateState(this.adjustmentApplication.application_id, 'denied', true);
      this.hide();
    }
  }
}
</script>
