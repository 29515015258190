import denyModal from "./actions/denyModal";
import genericAdjustmentApplicationModal from "./genericAdjustmentApplicationModal";
import manualEventLogModal from "./actions/manualEventLogModal";
import pdfModal from "./pdfModal";
import proceedModal from "./actions/proceedModal";
import requestModal from "./actions/requestModal";
import snoozeModal from "./actions/snoozeModal";

export default {
  genericAdjustmentApplicationModal,
  pdfModal,
  denyModal,
  manualEventLogModal,
  proceedModal,
  requestModal,
  snoozeModal,
  };
