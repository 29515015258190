/*
  This mixin handles generic modal logic, common to all details modals,
  like closing logic, fetching data, or preSave logic.
*/

export default {
  props: ["close", "undo"],
  data() {
    return {
      hideFlag: false,
      PDF_URL: "",
      PDF_DENIAL_URL: "",
      date: this.dateToLocale(new Date().toString()).toUpperCase(),
    };
  },
  watch: {
    close() {
      this.hideFlag = true;
      this.closeModal();
    },
  },
  computed: {
    modalData() {
      return this.$store.state.ui.modalData;
    },
  },
  methods: {
    preSave() {
      /*
        This generic modal method runs before calling
        the particular save method of a record details modal.
      */
      this.save();
    },
    cancel({ bvEvent, modalId }) {
      if (!modalId || modalId === this.modalId) {
        if (this.cancelWarning && !this.hideFlag) {
          bvEvent && bvEvent.preventDefault();
          this.$bvModal.show("confirm-modal");
        } else {
          this.closeModal();
        }
      }
    },
    closeModal(force) {
      if (force) this.hideFlag = true;
      this.$bvModal.hide(this.modalId);
    },
    copyToClipboard() {
      const copyText = document.getElementById("textarea");
      copyText.select();
      copyText.setSelectionRange(0, 99999); // for mobile devices
      document.execCommand("copy");
      copyText.blur();
      this.notify("info", "Text copied to clipboard", copyText.value);
    },
    viewPDF(url) {
      if (!url) {
        throw new Error("Resource URL not provided! You need to provide one");
      }
      this.$store.commit("ui/viewPDF", url);
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    getTimezoneOffset(d, tz) {
      // Get's an ISO8601 timezone offset string. i.e.: -07:00
      const a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
      a[1]--;
      const t1 = Date.UTC.apply(null, a);
      const t2 = new Date(d).setMilliseconds(0);
      const minutes = (t2 - t1) / 60 / 1000;

      return `${minutes > 0 ? '-' : '+'}${this.pad(Math.abs(minutes / 60), 2)}:${this.pad(minutes % 60, 2)}`;
    },
    dateToLocale(dateString, tz='UTC') {
      const dateObject = new Date(dateString + this.getTimezoneOffset(new Date(dateString), tz));
      const day = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][dateObject.getDay()];
      const datetime = dateObject.toLocaleString(
        "en-US",
        {
          timeZoneName:'short',
        }
      );
      return `${day}, ${datetime}`;
    },
  },
};
