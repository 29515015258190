
<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-alert show class='text-left m-0 pointer' href="#"
        v-b-toggle="'accordion-' + id"
        :variant="localValue ? 'success' : 'danger'"
      >
        <span class="alert-link">{{ headerText }}</span>
      </b-alert>
    </b-card-header>

    <b-collapse :visible="localValue === false" :id="'accordion-' + id" role="tabpanel">
      <b-card-body>
        <radioWithContext
          v-model="localValue"
        >
          <template v-slot:popoverBody>
            {{ popoverText }}
          </template>
          <template v-slot:details>
            <slot name="details">
            </slot>
          </template>
        </radioWithContext>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import radioWithContext from "./radioWithContext.vue";

let count = 0
export default {
  name: "promptCard",
  components: { radioWithContext },
  mixins: [],
  props: {
    id: {
      type: String,
      default: function() {
        return this.$options.name + '-' + count++;
      }
    },
    value: Boolean,
    headerText: String,
    popoverText: String,
  },
  emits: [
    'input',
  ],
  data() {
    return {}
  },
  computed: {
    localValue: {
      get() {
        return !!this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }

    },
  },
  methods: {
  },
  watch: {
  },
}
</script>
