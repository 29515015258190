<template>
  <b-container fluid>
    <div style="width: 208px">
      <b-sidebar
        class="customNavbar"
        width="208px"
        title="Sidebar"
        left
        shadow
        visible
        no-header
        no-close-on-route-change
      >
        <img
          src="@/assets/transparent_logo.png"
          style="width: 165px; margin-left: 21px"
          class="mt-3"
        />
        <b-nav vertical class="mt-4">
          <b-nav-item
            :active="isActive(route.name)"
            class="show text-uppercase"
            v-for="route in routes"
            :key="route.name"
            :to="{ name: route.name }"
          >
            {{ route.label }}
          </b-nav-item>
          <div class="mx-3 to-page-bottom">
            <b-button
              class="text-uppercase mb-2"
              variant="outline-light"
              @click="onSync"
            >
              Sync data with BAT database
            </b-button>

            <b-button
              class="text-uppercase"
              variant="outline-light"
              @click="onExportAll"
            >
              Export all applications
            </b-button>
          </div>
        </b-nav>
      </b-sidebar>
    </div>
    <div class="contentMargin mt-4 mr-0 pr-0">
      <router-view />
    </div>

    <!-- Confirmation Modals START-->
    <b-modal
      id="confirm-modal"
      header-class="detailsModalTitle pb-2"
      hide-header-close
      hide-footer
      size="sm"
    >
      <template #modal-title>
        Close Record Detail
        <b-button
          variant="light"
          class="primary ml-3 float-right"
          @click="
            close = !close;
            $bvModal.hide('confirm-modal');
          "
          >YES, CLOSE</b-button
        >
        <b-button
          variant="outline"
          class="white ml-3 float-right"
          @click="$bvModal.hide('confirm-modal')"
          >NO, DON'T CLOSE</b-button
        >
      </template>
      Are you sure? All approved task status will not be saved for future
      reference.
    </b-modal>

    <!-- Confirmation Modals END -->

    <component
      v-for="modal in modals"
      :is="modal"
      :key="modal.__file"
      :close="close"
      :undo="undo"
    ></component>
  </b-container>
</template>

<script>
import axios from "axios";
import modals from "@/components/modals";
import utils from "@/mixins/utils";
import { listAdjustApplications, setCreditStatus } from "@/apis/adjustmentApplications";

export default {
  components: {
    ...modals,
  },
  mixins: [utils],
  async created() {
    axios.interceptors.request.use((req) => {
      const user = localStorage.getItem("user");
      if (user) {
        const token = JSON.parse(user).token;
        req.headers.authorization = token;
      }
      return req;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.notify(
            "info",
            "Session expired",
            "You will be redirected to login page..."
          );
          setTimeout(() => {
            this.logOut();
          }, 3000);
        }
        return Promise.reject(error);
      }
    );

    // try {
    //   const {
    //     data: { items },
    //   } = await getUsers();
    //   this.$store.state.ui.users = items.filter((user) => user.active);
    // } catch (e) {
    //   this.notify("danger", "Could not fetch system's users", "" + e);
    // }
  },
  methods: {
    isActive(routeName) {
      return this.$route.name === routeName;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signIn");
    },

    onSync() {
      setCreditStatus()
        .then(() => {
          this.$route.query.application_id = ''

          this.notify(
            "info",
            "Succesfully updated credit status on page load.",
          );
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to update credit status on page load. Please try manually syncing.",
            e.response.data.message
          );
        });
    },

    onExportAll() {
      listAdjustApplications({as_csv: true, limit: 1000000})
        .then(({ data }) => {
          this.downloadCSV(data, 'adjustment_applications.csv');
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to export all adjustment applications.",
            e.response.data.message
          );
        });
    },
  },
  computed: {
    modals() {
      return modals;
    },
  },
  data() {
    return {
      close: false,
      undo: false,
      routes: [
        {
          name: "billAdjustments",
          label: "Bill Adjustments",
        },
        {
          name: "accountLookup",
          label: "Account Lookup",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.b-sidebar-body {
  background: #00416a;
}

.tab-border-bottom {
  background-color: white !important;
  border-bottom: solid #00416b;
}

.modal-sm {
  max-width: 500px !important;
  width: 500px !important;
}
</style>

<style scoped>
.customNavbar {
  width: 320px !important;
  text-align: left;
}

.nav-link {
  padding-left: 30px;
}

.contentMargin {
  margin-left: 208px !important;
}

a,
a:hover {
  color: white;
}

.active {
  background-color: rgba(255, 255, 255, 0.25);
}


.to-page-bottom{
 position: fixed;
  bottom: 25px;
  right: 20px;
}

</style>
