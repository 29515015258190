<template>
  <generic-modal
    modalTitle="Adjustment Application"
    :modal-id="modalId"
    :undo="undo"
    :noActions="true"
    :saveEnabled="false"
    @modalHide="cancel"
    @modalShow="show"
    @cancel="cancel"
  >
    <template v-slot:action-buttons>
      <b-button
        v-if="state == 'waiting'"
        variant="outline"
        class="white ml-3 float-right"
        @click="updateState(modalData.application_id, 'action_needed'); closeModal();"
      >MOVE APPLICATION TO ACTION NEEDED</b-button>

      <b-button
        v-if="state == 'ready_for_billing'"
        variant="outline"
        class="white ml-3 float-right"
        @click="updateState(modalData.application_id, 'completed'); closeModal();"
      >MOVE APPLICATION TO CREDIT ENTERED</b-button>

      <b-dropdown right
        class='float-right'
        toggle-class='white ml-3'
        text="MOVE APPLICATION"
        variant="outline"
      >
        <b-dropdown-item
          v-if='state != "action_needed"'
          size='sm' @click='updateState(modalData.application_id, "action_needed"); closeModal();'
        >to Action Needed</b-dropdown-item>

        <b-dropdown-item
          v-if='state != "waiting"'
          size='sm' @click='$bvModal.show("snooze-modal")'
        >to Snoozing</b-dropdown-item>

        <b-dropdown-item
          v-if='state != "ready_for_billing"'
          :disabled="!is_drought ? (isApprovable !== true) : (modalData.is_director_approved !== true)"
          size='sm'
          @click='$bvModal.show("proceed-modal")'
        >to Ready for Billing</b-dropdown-item>

        <b-dropdown-item
          v-if='state != "completed" && state != "ready_for_billing"'
          size='sm' @click='updateState(modalData.application_id, "completed"); closeModal();'
        >to Credit Entered</b-dropdown-item>

        <b-dropdown-item
          v-if='state != "waiting_for_director_approval"'
          size='sm' @click='updateState(modalData.application_id, "waiting_for_director_approval"); closeModal();'
        >to Waiting for Director Approval</b-dropdown-item>

        <b-dropdown-item
          v-if='state != "denied"'
          size='sm' @click='$bvModal.show("deny-modal")'
        >to Denied</b-dropdown-item>
      </b-dropdown>
    </template>

    <template v-slot:mainBody>
        <!-- Collapsables -->
      <div class='mt-3'>
        <!-- Recent Activity -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-alert class='text-center m-0 pointer' block href="#" v-b-toggle.accordion-1 variant="secondary" show><span class=''>Display Recent Activity</span></b-alert>
          </b-card-header>
          <b-collapse id="accordion-1" role="tabpanel">
            <b-card-body>
              <p class='text-center mt-3'>
                <span v-if='recentActivity.length == 0'>No recent application activity found!</span>
              </p>
              <b-table class='' fixed v-if='recentActivity.length != 0' borderless head-variant='light' :items="recentActivity" :fields='recentActivityFields'>

                <template v-slot:cell(show_email)="row">
                  <b-form-checkbox v-if='row.item.email_body' v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
                </template>
                <template slot="row-details" slot-scope="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col v-html="row.item.email_body.split('\n').join('<br>')"></b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Attachments -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-alert class='text-center m-0 pointer' block href="#" v-b-toggle.accordion-3 variant="secondary" show><span class=''>Display Attachments</span></b-alert>
          </b-card-header>
          <b-collapse id="accordion-3"  role="tabpanel">
            <b-card-body>
              <div class="row justify-content-center">
                <div class="col-10">
                  <p class='text-center mt-3'>
                    <span v-if='!modalData.attachments'>No Attachments Found!</span>
                  </p>
                  <ul>
                    <!-- eslint-disable-next-line vue/require-v-for-key -->
                    <li v-for="(item, index) in modalData.attachments">
                      <a href="#" @click.prevent="viewPDF(item)">Attachment {{ index + 1 }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Recent Call Notes -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-alert class='text-center m-0 pointer' block href="#" v-b-toggle.accordion-4 variant="secondary" show><span class=''>Display Recent Call Notes</span></b-alert>
          </b-card-header>
          <b-collapse id="accordion-4"  role="tabpanel">
            <b-card-body>
              <p class='text-center mt-3'>
                <span v-if='recentCallNotes.length == 0'> No JDE call notes found!</span>
                <!-- <b-spinner v-if='recentCallNotes.length == 0' label="Loading..."></b-spinner> -->
              </p>
              <b-table class='' fixed v-if='recentCallNotes.length != 0' borderless small head-variant='light' :items="recentCallNotes" :fields='recentCallNotesFields'></b-table>
            </b-card-body>
          </b-collapse>
        </b-card>
        <hr>
      </div>

        <!-- Prompts -->
      <div id='prompts' class='mt-5'>
        <div class="overlay"
          v-if="state != 'action_needed' && state != 'waiting_for_director_approval'"
          v-b-tooltip.hover title='To edit, move current application to "Action Needed"' >
        </div>

        <div v-if="loadingCustomer">Loading customer data from JDE</div>
        <div v-else>
          <h1 class='mb-4'>Prompts</h1>

          <promptCard
            v-model="isAccountValid"
            headerText="Is this account number in JDE?"
            popoverText="Try using the address from application to find the correct account number."
          >
            <template v-slot:details>
              <b-table fixed small head-variant='light' :items="accountInJDEItems">
                <template v-slot:cell(account_number_from_application)>
                  <editFieldInput
                    :value="modalData.account_number"
                    @onSubmit="(newVal) => updateAdjustApplication({...modalData, account_number: newVal})"
                  ></editFieldInput>
                </template>
              </b-table>
            </template>
          </promptCard>

          <promptCard
            v-model="doesAddressMatchJDE"
            headerText="Does customer address from application match address from JDE?"
            popoverText="Try using the address from JDE to find the correct address."
          >
            <template v-slot:details>
              <b-table fixed small head-variant='light' :items="addressMatchItems">
                <template v-slot:cell(address_from_application)>
                  <editFieldInput v-model="modalData.service_address_full"></editFieldInput>
                </template>
              </b-table>
            </template>
          </promptCard>

          <promptCard
            v-model="doesEmailMatchJDE"
            headerText="Is customer email address in JDE?"
            popoverText="Please add email address to JDE."
          >
            <template v-slot:details>
              <b-table fixed small head-variant='light' :items="emailMatchItems">
                <template v-slot:cell(email_from_application)>
                  <editFieldInput
                    :value="modalData.email"
                    @onSubmit="(newVal) => updateAdjustApplication({...modalData, email: newVal})"
                  ></editFieldInput>
                </template>
              </b-table>
            </template>
          </promptCard>

          <promptCard
            v-model="doesPhoneNumberMatchJDE"
            headerText="Is customer phone number in JDE?"
            popoverText="Please add phone number to JDE."
          >
            <template v-slot:details>
              <b-table fixed small head-variant='light' :items="phoneMatchItems">
                <template v-slot:cell(phone_number_from_application)>
                  <editFieldInput
                    :value="modalData.phone"
                    @onSubmit="(newVal) => updateAdjustApplication({...modalData, phone: newVal})"
                  ></editFieldInput>
                </template>
              </b-table>
            </template>
          </promptCard>

           <promptCard
            v-model="isAdjustmentValid"
            headerText="Does the bill adjustment policy allow for this type of adjustment?"
            popoverText="Looks like this may not be a valid request."
          >
            <template v-slot:details>
              <b-card no-body class="my-1">
                <b-collapse id="accordion-isCovidRelated" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group class="pt-4 pb-5">
                          <b-form-radio-group
                            v-model="isCovidRelated"
                            :options="isCovidRelatedOptions"
                          ></b-form-radio-group>
                        </b-form-group>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-popover container='reason_for_adjustment_zachor' :show.sync="pool_fill" target="reason_for_adjustmentPop" placement='left' class='border border-danger'>
                If the customer has had a pool fill adjustment &lt;5 yrs ago (see call notes), deny. Otherwise, proceed.
              </b-popover>
              <b-table class='mb-12 text-center' fixed small head-variant='light' :items="validAdjustmentItems"></b-table>
              <span id='reason_for_adjustmentPop'>&nbsp;</span>
              <b-card>
                <b-table class='mb-12 text-center' v-if='poolItems != []' borderless fixed stacked head-variant='white' :items="poolItems" ></b-table>
                <b-table class='mb-12 text-center' v-if='repairItems != []' borderless fixed stacked head-variant='white' :items="repairItems" ></b-table>
                <b-table class='mb-12 text-center' v-if='covidItems != []' borderless fixed stacked head-variant='white' :items="covidItems" ></b-table>
                <b-table class='mb-12 text-center' v-if='detailItems != []' borderless fixed stacked head-variant='white' :items="detailItems" ></b-table>
                <b-table class='mb-12 text-center' v-if='irrigationItems != []' borderless fixed stacked head-variant='white' :items="irrigationItems" ></b-table>
              </b-card>
              <b-button class='mt-1' block size="sm" v-b-toggle.collapse-policy variant="outline-secondary">Display Bill Adjustment Policy</b-button>
            </template>
          </promptCard>

          <!-- Other Applications -->
          <b-card class="mb-1" style="background-color: #ffe7d1;" v-if='otherActiveApplications.length != 0'>
            <h3 class='mb-4'>Other Applications</h3>

            <p class='mb-1'>This account has other open applications:</p>
            <b-table
              :fields='multiAppsFields'
              :items="otherActiveApplications"
              :sort-by="'bill_to_adjust_date'"
              :sort-desc="true"
              class='smallerFont mb-5'
              head-variant='light'
              small
            >
            </b-table>
          </b-card>

          <!-- Recent Bills -->
          <b-card>
            <h3 class='mb-4'>Recent Bills</h3>

            <p class='text-center mt-3'>
              <b-spinner v-if='recentBills.length == 0 && isAccountValid' label="Loading..."></b-spinner>
              <span v-if='!isAccountValid'> No bills found!</span>
            </p>


            <div id='multi_apps_zachor' style='z-index: 1 !important;'></div>
            <b-table
              v-if='recentBills.length != 0'
              @row-clicked="onBillSelect"
              :fields='recentBillsFields'
              :items="recentBills"
              :tbody-tr-class="billRowClass"
              :sort-by="'gl_date'"
              :sort-desc="true"
              class=' mb-5'
              head-variant='light'
              select-mode="single"
              selected-variant="primary"
              fixed
              foot-clone
              hover
              selectable
              small
            >
              <template v-slot:cell(adjustment)="data">
                <span v-html="data.value"></span>
              </template>
            </b-table>

            <radioWithContext
              v-model="isValidHighbill"
              headerText="Is the date of the selected high bill within the 90 day grace period from the submission date or has an exception been made?"
            >
              <template v-slot:popoverBody>
                If customer has called in the past regarding the issue at hand, proceed. Otherwise, check with senior staff.
              </template>
              <template v-slot:details>
                <b-table class=' text-center' fixed small head-variant='light' :items="validHighbillItems" :fields='validHighbillFields'>
                  <template v-slot:cell(Difference)="data">
                    <span v-html="data.value"></span>
                  </template>
                </b-table>
                <span id='validHighbillPop_yes'>&nbsp;</span>
                <b-popover  container='validHighbill_zachor' :show.sync="validNotes" target="validHighbillPop_yes" placement=''>
                  <i>Valid request!</i>
                  <b-button class='mt-1' block size="sm" variant="warning" @click="$bvModal.show('jde-notes-modal')">
                    Add notes to JDE
                  </b-button>
                </b-popover>
              </template>
            </radioWithContext>

            <radioWithContext
              v-model="isSelectedAdjustmentApproved"
              headerText="Do you approve the selected high bill to adjust?"
            >
              <template v-slot:popoverBody>
                You can select a different bill from the table above.
              </template>
              <template v-slot:details>
                <b-table class=' text-center' fixed small head-variant='light' :items="selectedAdjustmentItems" :fields="selectedAdjustmentFields" tbody-tr-class="table-primary"></b-table>
              </template>
            </radioWithContext>

          </b-card>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <b-popover
        v-if="state == 'action_needed' && is_drought && modalData.is_director_approved == null"
        variant='warning' target="footerButtons" show placement="top"
      >
        We're in water shortage!
        <b-button class='mt-1' block size="sm" variant="warning" @click='updateState(modalData.application_id, "Waiting for Director Approval"); closeModal();'>Send to Director for full approval.</b-button>
      </b-popover>

      <b-popover
        v-if="state == 'action_needed' && is_drought && modalData.is_director_approved === true"
        variant='success' target="footerButtons" show placement="top"
      >
        This application has been approved by a Director!
        <br><br><b>Please proceed, and complete approval.</b>
      </b-popover>

      <b-popover
        v-if="state == 'action_needed' && is_drought && modalData.is_director_approved === null && modalData.directors_message != null"
        variant='warning' target="footerButtons" show placement="top"
      >
        Additional actions have been requested by a Director:
        <b-form-textarea
          id="textarea"
          class='my-4'
          v-model='modalData.directors_message'
          rows="3"
          max-rows="6"
          disabled
        ></b-form-textarea>
        <b-button class='' block size="sm" variant="warning" @click='updateState(modalData.application_id, "Waiting for Director Approval"); closeModal();'>
          Send back to Director for full approval.
        </b-button>
      </b-popover>

      <b-popover
        v-if="state == 'action_needed' && is_drought && modalData.is_director_approved === false"
        variant='danger' target="footerButtons" show placement="top"
      >
        This application has been denied by a Director with the feedback below:
        <b-form-textarea
          id="textarea"
          class='my-4'
          v-model='modalData.directors_message'
          disabled
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b>Please proceed, and complete denial.</b>
      </b-popover>

      <b-popover
        v-if="state == 'waiting_for_director_approval' && is_drought && modalData.is_director_approved == null"
        variant='dark' target="footerButtons" show placement="top"
      >
        <h6>Do you approve this adjustment?</h6>
        <b-button class='mt-1 mb-4' block size="sm" variant="success" @click='makeDirectorDecision("approval")'>Approve</b-button>
        <i>If not, please provide feedback below and send back to Conservation.</i>
        <b-form-textarea
          id="textarea"
          v-model='temp_directors_message'
          rows="3"
          max-rows="6"
        ></b-form-textarea>
          <b-button class='mt-1' block size="sm" variant="warning" @click='makeDirectorDecision("request")' :disabled="temp_directors_message == ''">
            Request Additional Actions
          </b-button>
          <b-button class='mt-1' block size="sm" variant="danger" @click='makeDirectorDecision("denial")' :disabled="temp_directors_message == ''">
            Deny
          </b-button>
      </b-popover>

      <div id='footerButtons'  class="sticky-bottom pt-2 " style='z-index: 1000 !important; background-color: rgba(108,117,125, .95);'>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center pb-2 pt-1">
              <b-button v-b-modal.proceed-modal class='smallerFont mb-1 mr-2 px-5' size='sm' variant="success"
                :disabled='!is_drought ? (isApprovable !== true) : (modalData.is_director_approved !== true)'
              >Approve</b-button>
              <b-button v-b-modal.snooze-modal  class='smallerFont mb-1 mr-2 px-5' size='sm' variant="warning">Snooze</b-button>
              <b-button v-b-modal.request-modal class='smallerFont mb-1 mr-2 px-5' size='sm' variant="warning">Email</b-button>
              <b-button v-b-modal.deny-modal    class='smallerFont mb-1 px-5' size='sm' variant="danger">Deny</b-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import editFieldInput from "../editFieldInput.vue";
import promptCard from "../promptCard.vue";
import radioWithContext from "../radioWithContext.vue";
import genericModal from "./genericModal";
import modalMixin from "@/mixins/modal.js";
import { getAdjustApplicationEvents, getOtherActiveAjustApplications, updateAdjustApplication } from "@/apis/adjustmentApplications";
import { getCustomer, getCustomerRecentCallNotes, getCustomerExpectedBillDate, getCustomerRecentBills } from "@/apis/customers";
import utils from "@/mixins/utils";


export default {
  name: "genericAdjustmentApplicationModal",
  components: { genericModal, promptCard, editFieldInput, radioWithContext },
  mixins: [modalMixin, utils],
  methods: {
    // no save action possible
    show({ modalId }) {
      if (modalId === this.modalId) {
        this.hideFlag = false;
        this.fetchAdjustApplicationHistory(this.modalData.application_id);
        this.fetchCustomerRecentCallNotes(this.modalData.account_number);
        this.fetchOtherActiveAdjustApplications(this.modalData.application_id, this.modalData.account_number);
        this.fetchCustomer(this.modalData.account_number);
      }
    },

    updateAdjustApplication(application, refresh=true) {

      updateAdjustApplication(this.modalData.application_id, application)
        .then(({data}) => {
          // Update modal data
          this.$store.commit('ui/setModalData', data);
          if (refresh) {
            // Trigger page refresh
            this.$store.commit("ui/setRefreshRows", "*");
          }
        })
        .catch((e) => {
          this.notify("danger", "Could not update application", "" + e);
        });
    },

    fetchAdjustApplicationHistory(application_id) {
      this.loadingCount++;
      getAdjustApplicationEvents(application_id)
        .then(({ data }) => {
          let recentActivity = data.items.filter(function (el) {
            return (el.description.slice(0,9) != 'DEBUG_LOG')
          })
          this.recentActivity = recentActivity;
          this.$store.commit('ui/setRecentActivity', recentActivity);
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Adjustment Application history could not be retrieved",
            e.response.data.message
          );
        })
        .finally(() => {
          this.loadingCount--;
        });
    },

    fetchCustomerRecentCallNotes(account_number) {
      this.loadingCount++;
      getCustomerRecentCallNotes(account_number)
        .then(({ data }) => {
          this.recentCallNotes = data.items;
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Customer's recent call notes could not be retrieved",
            e.response.data.message
          );
        })
        .finally(() => {
          this.loadingCount--;
        });
    },

    fetchOtherActiveAdjustApplications(application_id, account_number) {
      this.loadingCount++;
      getOtherActiveAjustApplications(application_id, account_number)
        .then(({ data }) => {
          this.otherActiveApplications = data;
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Other active applications could not be retrieved",
            e.response.data.message
          );
        })
        .finally(() => {
          this.loadingCount--;
        });
    },

    fetchCustomer(account_number) {
      let vm = this;

      vm.loadingCount++;
      vm.loadingCustomer = true;

      const selected_submission_date = vm.modalData.created_ts.toString()
      vm.isAdjustmentValid = vm.modalData.adjustment_is_valid;
      vm.validAdjustmentItems = [{'Reason For Adjustment': vm.modalData.reason.toString()}];

      getCustomer(account_number)
        .then(({ data }) => {
          if (data.length != 0) {
            vm.isAccountValid = true;
            vm.jde_customer = data[0];

            const jde_bill_cycle = vm.jde_customer.bill_cycle;
            const jde_service_address_number = vm.jde_customer.service_address;

            vm.doesAddressMatchJDE = vm.doAddressesMatch(vm.jde_customer.street_address, vm.modalData.service_address_full);
            vm.doesEmailMatchJDE = vm.doEmailsMatch(data, vm.modalData.email);
            vm.doesPhoneNumberMatchJDE = vm.doPhoneNumbersMatch(data, vm.modalData.phone);

            getCustomerExpectedBillDate(account_number)
              .then(({ data }) => {
                const next_expected_bill_date = data.items[0].next_expected_bill_date;

                getCustomerRecentBills(account_number)
                  .then(({ data }) => {
                    vm.recentBills = data.items;

                    let highBillDate = '';
                    if (vm.modalData.bill_to_adjust_date) {
                      highBillDate = vm.modalData.bill_to_adjust_date

                      data.items.forEach(function(element, idx) {
                        if (element.gl_date == highBillDate) {

                          vm.selectedAdjustmentItems = [element]
                          vm.recentBills.forEach(function(element) {
                            element.status = ''
                          });
                          vm.recentBills[idx].status = 'highbill'
                        }
                      })
                    } else {
                      let max_adjustment = -9999
                      let selected_bill = null
                      let high_bill_idx = null

                      // for each bill in the last year, we want to loop through and find the bill with the HIGHEST adjustment
                      // that has nonzero tier 4 use, and also occurred within 60 days of the adjustment application
                      data.items.forEach(function(element, idx) {
                        const days_before_submission = vm.dateDiff(new Date(element.gl_date), new Date(selected_submission_date), false)

                        // only update if higher than previous max credit
                        if (element.tier4_allocation  != 0 && element.adjustment > max_adjustment
                            && days_before_submission < 60 && days_before_submission > -60) {
                          max_adjustment = element.adjustment;
                          selected_bill = element;
                          high_bill_idx = idx;
                        }
                      });

                      if(selected_bill){
                        highBillDate = selected_bill.gl_date;
                        vm.selectedAdjustmentItems = [selected_bill];
                        vm.recentBills.forEach(function(element) {
                          element.status = '';
                        });
                        vm.recentBills[high_bill_idx].status = 'highbill';
                      }
                    }

                    // initalize data
                    if (!vm.modalData.bill_cycle) {
                      vm.modalData.bill_cycle = jde_bill_cycle;
                    }
                    if (!vm.modalData.service_address_number) {
                      vm.modalData.service_address_number = jde_service_address_number;
                    }
                    // "Only push wake up date forward from action_needed or waiting_for_director_approval tabs"
                    if (vm.state == 'action_needed' || vm.state == 'waiting_for_director_approval') {
                      vm.modalData.wake_up_date = next_expected_bill_date;
                    }

                    vm.updateHighBillDate(highBillDate);
                  })
                  .catch((e) => {
                    vm.notify(
                      "danger",
                      "Customer's recent bills could not be retrieved",
                      e.response.data.message
                    );
                  })
              })
              .catch((e) => {
                vm.notify(
                  "danger",
                  "Customer's expected bill date could not be retrieved",
                  e.response.data.message
                );
              })
          } else {
            vm.isAccountValid = false;
            vm.jde_customer = {};
          }
        })
        .catch((e) => {
          vm.notify(
            "danger",
            "Customer could not be retrieved",
            e.response.data.message
          );
        })
        .finally(() => {
          vm.accountInJDEItems = [
            {'account_number_from_application': null, 'Found in JDE?': vm.isAccountValid ? 'Yes!':  'No :('},
          ];
          vm.addressMatchItems = [
            {'address_from_application': null, 'Address from JDE': vm.jde_customer?.street_address},
          ];
          vm.emailMatchItems = [
            {'email_from_application': null, 'Email address from JDE': vm.jde_customer?.email},
          ];
          vm.phoneMatchItems = [
            {'phone_number_from_application': null, 'Phone number from JDE': vm.jde_customer?.phone},
          ];

          vm.loadingCount--;
          vm.loadingCustomer = false;
        });
    },

    dateDiff(date1, date2, set_high_bill) {
      let diffTime = date2.getTime() - date1.getTime()
      let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (set_high_bill === true) {

        if (Math.abs(diffDays) <= 30){
          this.isValidHighbill = true

        } else {
          this.isValidHighbill = false
        }
      }

      return diffDays

    },

    strip(str) {
      if (str == null) {
        str = ''
      }
      return str.replace(/ /g,'');
    },

    doAddressesMatch(address1, address2) {
      // clean addresses
      function ifContainsThenRemove(stringToClean, substringToReplace) {
        return stringToClean.includes(substringToReplace) ? stringToClean.replace(substringToReplace, "") : stringToClean
      }

      address1 = address1.toLowerCase()
      address2 = address2.toLowerCase()

      let substrings_to_remove = ['.', ' place', ' pl', ' rd', ' road', ' ct', ' court', ' lane', ' ln', ' avenue', ' ave', ' drive', ' dr', ' circle', ' cir', ' RIDGE', ' rdg']
      for (let substring_idx in substrings_to_remove) {
        let substring = substrings_to_remove[substring_idx]
        address1 = ifContainsThenRemove(address1, substring)
        address2 = ifContainsThenRemove(address2, substring)
      }

      return this.strip(address1) == this.strip(address2);
    },

    doEmailsMatch(jde_customers, expectedEmail) {
      // Check if a single email matches the expected email address.
      expectedEmail = this.strip(expectedEmail ? expectedEmail.toLowerCase() : expectedEmail);

      // email match check
      for (const jde_customer of jde_customers) {
        // clean emails
        let jde_email = jde_customer.email;
        jde_email = this.strip(jde_email ? jde_email.toLowerCase() : jde_email);

        if (jde_email == expectedEmail) {
          return true;
        }
      }
      return false;
    },

    doPhoneNumbersMatch(jde_customers, expectedPhoneNumber) {
      // Check if a single phone number matches the expected phone number.
      // clean number
      function formatPhoneNumber(phoneNumberString) {
        // https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
      }

      expectedPhoneNumber = formatPhoneNumber(expectedPhoneNumber);

      for (const jde_customer of jde_customers) {
        if (formatPhoneNumber(jde_customer.phone) == expectedPhoneNumber) {
          return true;
        }
      }
      return false;
    },

    billRowClass(item) {
      if (item) {
        if (item.status === 'highbill') return 'table-primary';
      }
      return '';
    },

    updateHighBillDate(highBillDate) {
      let selected_submission_date = this.modalData.created_ts.toString();
      let date1 = new Date(highBillDate);
      let date2 = new Date(selected_submission_date);
      let diffDays = this.dateDiff(date1, date2, true)

      if (isNaN(diffDays)){
        diffDays = '???'
      } else if (diffDays <= 0) {
        diffDays = `Selected high bill is <br><b>${Math.abs(diffDays)} days after</b><br> request`
      } else {
        diffDays = `Selected high bill is <br><b>${Math.abs(diffDays)} days before</b><br> request`
      }

      this.validHighbillItems = [{
        'Selected High Bill Date': highBillDate,
        'Request Date': selected_submission_date.split('T')[0],
        'Difference': diffDays,
        _cellVariants: {
          'Selected High Bill Date' : 'primary',
          'Request Date' : this.isValidHighbill ? 'success' : 'danger',
          'Difference' : this.isValidHighbill ? 'success' : 'danger'
        }
      }]
    },

    onBillSelect(item){
      this.recentBills.forEach(function(element) {
        element.status = ''
      })
      let highBillDate = item.gl_date;
      this.updateHighBillDate(highBillDate);

      this.selectedAdjustmentItems = [item];
    },


    makeDirectorDecision(decision, log_event=true) {
      this.modalData.directors_message = this.temp_directors_message;

      if (decision == 'approval') {
        this.modalData.is_director_approved = true;
      } else if (decision == 'request') {
        this.modalData.is_director_approved = null;
      } else if (decision == 'denial') {
        this.modalData.is_director_approved = false;
      }

      if (log_event) {
        let feedback = ''
        if (this.modalData.directors_message) {
          feedback = ` with feedback "${this.modalData.directors_message}"`
        }
        this.updateState(this.modalData.application_id, 'action_needed', true, `This application received director ${decision} from ${this.current_user}${feedback}.`)
      } else {
        this.updateState(this.modalData.application_id, 'action_needed')
      }
    },

    openRequestModal() {
      this.$store.dispatch("ui/openNotesModal", {modalId: 'request-modal', ui: this});
    },
    openDenyModal() {
      this.$store.dispatch("ui/openNotesModal", {modalId: 'deny-modal', ui: this});
    },

    reset() {
      // Reset modal's data
      this.loadingCount = 0;
      this.loadingCustomer = false;

      this.recentActivity = [];
      this.selectedAttachments = [];
      this.recentCallNotes = [];
      this.recentBills = [];
      this.otherActiveApplications = [];
      this.selectedAdjustmentItems = [];
      this.validHighbillItems = [];

      this.jde_customer = {};

      this.isAccountValid = false;
      this.doesAddressMatchJDE = false;
      this.doesEmailMatchJDE = false;
      this.doesPhoneNumberMatchJDE = false;
      this.isAdjustmentValid = null;
      this.isValidHighbill = false;
      this.isSelectedAdjustmentApproved = false;
      this.validNotes = false;

      this.accountInJDEItems = [];
      this.addressMatchItems = [];
      this.emailMatchItems = [];
      this.phoneMatchItems = [];
      this.validAdjustmentItems = [];

      // are we in "water shortage";
      this.is_drought = false;
      this.temp_directors_message = '';
    },
  },
  computed: {
    current_user() {
      return this.$store.state.auth.user.username.toUpperCase();
    },
    state () {
      return {
        'Action Needed': 'action_needed',
        'Waiting': 'waiting',
        'Ready for Billing': 'ready_for_billing',
        'Completed': 'completed',
        'Denied': 'denied',
        'Waiting for Director Approval': 'waiting_for_director_approval',
      }[this.modalData.state];
    },
    isApprovable() {
      return (
        this.isAccountValid
        && this.doesAddressMatchJDE
        && this.doesEmailMatchJDE
        && this.doesPhoneNumberMatchJDE
        && this.isAdjustmentValid
        && this.isValidHighbill
        && this.isSelectedAdjustmentApproved
      );
    },

    saveEnabled() {
      return false;
    },
    cancelWarning() {
      return false;
    },
    loading() {
      return this.loadingCount > 0;
    },
    isCovidRelated() {
      return !!this.modalData.isCovidRelated;
    },
    pool_fill() {
      return this.modalData.pool_fill_reason !== null;
    },
    poolItems() {
      if (this.pool_fill) {
        return [
          {
            'pool_fill_reason' : this.modalData.pool_fill_reason.toString(),
            'pool_fill_date' : this.modalData.pool_fill_date.toString(),
            'pool_avg_depth_feet' : this.modalData.pool_avg_depth_feet.toString(),
            'pool_length_feet' : this.modalData.pool_length_feet.toString(),
            'pool_width_feet': this.modalData.pool_width_feet.toString(),
            'gallons': this.modalData.gallons.toString(),
            'billing_units': (this.modalData.gallons*0.00133681).toFixed(4).toString()
          }
        ];
      } else {
        return [];
      }
    },
    irrigationItems() {
      if (this.modalData.timer_description !== null) {
        return [{'timer_description': this.modalData.timer_description.toString()}];
      } else {
        return [];
      }
    },
    repairItems() {
      if (this.modalData.repair_description !== null) {
        return [{
          'Repair Description': this.modalData.repair_description.toString(),
          'Repair Date': this.modalData.leak_repair_date,
        }];
      } else {
        return [];
      }
    },
    covidItems() {
      if (this.modalData.covid_reason !== null) {
        return [{'Emergency Declaration Reason': this.modalData.covid_reason.toString()}];
      } else {
        return [];
      }
    },
    detailItems() {
      if (this.modalData.details !== null) {
        return [{'Details': this.modalData.details.toString()}];
      } else if (this.modalData.other_details !== null) {
        return [{'Details': this.modalData.other_details.toString()}];
      }else {
        return [];
      }
    },
  },
  watch: {
    selectedAdjustmentItems: {
      handler() {
        if (this.selectedAdjustmentItems[0]) {

          // If any of the values are different from the current application values, then ask the user to approve it again
          if (
            this.modalData.bill_to_adjust_date !== this.selectedAdjustmentItems[0].gl_date
            || this.modalData.bill_to_adjust_amount !== this.selectedAdjustmentItems[0].total_bill
            || this.modalData.bill_amount_after_adjustment !== this.selectedAdjustmentItems[0].bill_amount_after_adjustment
            || this.modalData.bill_credit !== this.selectedAdjustmentItems[0].adjustment
          ) {
            this.isSelectedAdjustmentApproved = undefined;
          }

          this.modalData.bill_to_adjust_date = this.selectedAdjustmentItems[0].gl_date;
          this.modalData.bill_to_adjust_amount = this.selectedAdjustmentItems[0].total_bill;
          this.modalData.bill_amount_after_adjustment = this.selectedAdjustmentItems[0].bill_amount_after_adjustment;
          this.modalData.bill_credit = this.selectedAdjustmentItems[0].adjustment;
        }
        else {
          console.log('No selectedAdjustmentItems!');
        }
      },
      deep: true
    },
    is_drought: function(val) {
      let vm = this
      console.log('drought change')
      let is_director_approved_col = {
        key: 'is_director_approved',
        sortable: true,
        label:'Director Reviewed',
        class: 'text-center',
        formatter: (value, key, item) => {
          if (value !== null || item.directors_message != null) {
              return 'Yes'
          } else {
            return ''
          }
        }
      }

      if (val === true) {
        vm.tableFields.splice(2, 0, is_director_approved_col);
      }
    },
    isSelectedAdjustmentApproved: function(newVal) {
      if (newVal === true) {
        // State didn't change, so the application stays on the same page.
        // no refresh needed
        this.updateAdjustApplication(this.modalData, false);
      }
    }
  },
  created() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // Ensure we reset data when the modal is hidden so that we don't show the previous client's data when it's opened again
      if (modalId === this.modalId) {
        this.reset();
      }
      else if (['proceed-modal', 'snooze-modal', 'deny-modal'].includes(modalId)) {
        this.closeModal();
      }
    });
  },
  data() {
    return {
      loadingCount: 0,
      loadingCustomer: false,

      modalId: "generic-adjustment-application-modal",
      recentActivity: [],
      selectedAttachments: [],
      recentCallNotes: [],
      recentBills: [],
      otherActiveApplications: [],
      selectedAdjustmentItems: [],
      validHighbillItems: [],

      jde_customer: {},

      isAccountValid: false,
      doesAddressMatchJDE: false,
      doesEmailMatchJDE: false,
      doesPhoneNumberMatchJDE: false,
      isAdjustmentValid: null,
      isValidHighbill: false,
      isSelectedAdjustmentApproved: undefined,
      validNotes: false,

      accountInJDEItems: [],
      addressMatchItems: [],
      emailMatchItems: [],
      phoneMatchItems: [],
      validAdjustmentItems: [],

      isCovidRelatedOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],

      recentActivityFields: [
        {
          key: 'description',
          label: 'What?',
        },
        {
          key: 'datetime',
          label: 'When?',
          formatter: (value) => {
            let today = new Date();
            let dateified_date = new Date(value + "+00:00");
            let cleaned_date = dateified_date.toLocaleString();
            return `${this.dateDiff(dateified_date, today, false)} days ago (${cleaned_date})`;
          },
        },
        {
          key: 'username',
          label: 'Who?',
        },
        {
          key: 'show_email',
          label: 'Show Email (If Applicable)',
        }
      ],

      recentCallNotesFields: [
        {
          key: 'date_from',
          label: 'Call Date',
          sortable: true
        },
        {
          key: 'code',
          sortable: true
        },
        {
          key: 'note_user',
          label: 'User',
          sortable: true
        }
      ],
      multiAppsFields: [
      {
        key: 'created_ts',
        label:'Applied On',
        sortable: true,
        formatter: (value) => {
          return value.split('T')[0];
        }
      },
        {
          key: 'bill_to_adjust_date',
          label:'Selected High Bill',
        },
        {
          key: 'state',
          label:'Current State',
          formatter: (value) => {
            const mapper = {
              action_needed: 'Action Needed',
              waiting: ' Waiting',
              ready_for_billing: 'Ready for Billing',
              completed: 'Completed',
              denied: 'Denied',
              waiting_for_director_approval: 'Waiting for Director Approval',
            }
            return mapper[value];
          }
        }
      ],
      recentBillsFields: [
        {
          key: 'gl_date',
          label: 'Bill Date',
          sortable: true,
          class: 'date-column',
        },
        {
          key: 'tier1_allocation',
          label: 'Tier 1',
          class: 'tier-column',
        },
        {
          key: 'tier2_allocation',
          label: 'Tier 2',
          class: 'tier-column',
        },
        {
          key: 'tier3_allocation',
          label: 'Tier 3',
          class: 'tier-column',
        },
        {
          key: 'tier4_allocation',
          label: 'Tier 4',
          class: 'tier-column',
        },
        {
          key: 'tier5_allocation',
          label: 'Tier 5',
          class: 'tier-column',
        },
        {
          key: 'total_bill',
          label: 'Total Bill',
          formatter: (value) => {
            if (value){
              return '$'+value.toFixed(2)
            }
          }

        },
        {
          key: 'adjustment',
          label: 'Resulting Credit',
          formatter: (value) => {
            if (value){
              return `<b>$${value.toFixed(2)}</b>`
            }
          }

        },
        {
          key: 'count_of_other_charges',
          label: 'Additional Charges',
          formatter: (value) => {
            if(value === 0){
              return ''
            }
            else{
              return value
            }
          }

        }
      ],
      validHighbillFields: [
        'Selected High Bill Date',
        'Request Date',
        'Difference'
      ],
      selectedAdjustmentFields: [
        {
          key: 'gl_date',
          label: 'Selected High Bill Date'
        },
        {
          key: 'total_bill',
          label: 'Original Bill',
          formatter: (value) => {
            if (value){
              return '$' + value.toFixed(2);
            }
          }
        },
        {
          key: 'bill_amount_after_adjustment',
          label: 'Adjusted Bill',
          formatter: (value) => {
            if (value){
              return '$' + value.toFixed(2);
            }
          }
        },
        {
          key: 'adjustment',
          label: 'Resulting Credit',
          formatter: (value) => {
            if (value){
              return '$' + value.toFixed(2);
            }
          }
        }
      ],

      // are we in "water shortage"?
      is_drought: false,
      temp_directors_message: '',
    };
  },
};
</script>

<style lang="scss">
  #multi_apps_zachor {
    .popover{
      z-index: 10 !important;

      .popover-body {
        font-size: .6rem;

        th > div {
          font-size: .6rem;
        }
      }
    }
  }

  #prompts {
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 0.25rem;
      background-color: rgba(52, 58, 64,0.5);
      z-index: 1000;
    }
  }

  .sticky-bottom {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
